import { css, useTheme } from '@emotion/react'
import { Link } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { maxWidth } from 'src/themes'

const CourseNav = (props: {
	links: {
		path: string
		label: string
	}[]
}) => {
	const theme = useTheme()

	return (
		<div
			css={css`
				width: 100%;
			`}>
			<ul
				css={css`
					display: flex;
					flex-wrap: wrap;
					justify-content: space-around;
					list-style: none;
					font-weight: 100;
					background: linear-gradient(
						rgba(255, 255, 255, 0.1) 80%,
						#fff 200%
					);
					border-radius: 50px;
					padding: 0 1rem;

					@include ${maxWidth[1]} {
						flex-direction: row;
						flex-wrap: wrap;
						align-items: stretch;
					}

					a[aria-current='page'] {
						color: ${theme.highlightColor};
						font-weight: 400;
					}

					li {
						margin: 0;
						flex: 1;
					}

					a {
						font-weight: 100;
						display: block;
						text-align: center;
						margin: 0;
						padding: calc(1.56rem / 2) 0;
						min-width: 150px;
					}
				`}>
				{props.links.map((link) => (
					<li key={link.label}>
						<Link to={link.path}>{link.label}</Link>
					</li>
				))}
			</ul>
		</div>
	)
}

export default CourseNav
