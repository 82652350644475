import React from 'react'
import { PageProps, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { MapFaqsQuery } from '@graphql-types'
import { css } from '@emotion/react'
import MAPLayout from './_layout'

const MAPFaqsPage = (props: PageProps<MapFaqsQuery>) => {
	const { t } = useTranslation()
	const course = props.data.course
	const faqs = props.data.faqs.nodes
	return (
		<MAPLayout course={course}>
			<h2>{t('courses:common.frequently_asked_question')}</h2>
			{faqs.map((faq) => (
				<div
					key={faq!.Question}
					css={css`
						margin-bottom: 2.45rem;
						width: 100%;

						h3 {
							font-weight: 500;
							padding-bottom: 0.25rem;
							margin-bottom: 0.25rem;
							color: #ffcc00;
						}
					`}>
					<h3>{faq!.Question}</h3>
					<p>{faq!.Answer}</p>
				</div>
			))}
		</MAPLayout>
	)
}

export default MAPFaqsPage

export const query = graphql`
	query MAPFaqs($language: String!) {
		...TranslationFragment
		...MAPCoursePageFragment
		faqs: allCockpitFaqCollection(filter: { _lang: { eq: $language } }) {
			nodes {
				Question
				Answer
			}
		}
	}
`
