import React, { PropsWithChildren } from 'react'
import Container from '@components/Container'
import SEO from '@components/SEO'
import { useTranslation } from 'react-i18next'
import { MapCoursePageFragmentFragment } from '@graphql-types'
import CourseMAPNav from './_nav'
import CourseMode from '@components/CourseMode'

export type Course = NonNullable<MapCoursePageFragmentFragment['course']>

export type SpecialEvent = NonNullable<
	MapCoursePageFragmentFragment['openDayEvent']
>

const MAPLayout = ({
	course,
	children,
}: PropsWithChildren<{
	course: Course
}>) => {
	const { t } = useTranslation()
	return (
		<Container>
			<SEO title={t('courses:map.title')} keywords={[]} />
			<CourseMode mode={course!.mode!} />
			<h1>{course!.name}</h1>
			<CourseMAPNav />
			{children}
		</Container>
	)
}

export default MAPLayout
