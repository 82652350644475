import CourseNav from '@components/CourseNav'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CourseMAPLocator, { useCountry } from './_locator'

const CourseMAPNav = () => {
	const { t } = useTranslation()
	const [_, countryPrefix] = useCountry()

	return (
		<>
			<CourseMAPLocator />
			<CourseNav
				links={[
					{
						path: countryPrefix + '/courses/MAP/',
						label: t('courses:common.overview'),
					},
					{
						path: countryPrefix + '/courses/MAP/details/',
						label: t('courses:common.curriculum'),
					},
					{
						path: countryPrefix + '/courses/MAP/outcomes/',
						label: t('courses:common.outcomes'),
					},
					{
						path: countryPrefix + '/courses/MAP/career-support/',
						label: t('courses:common.career_support'),
					},
					{
						path: countryPrefix + '/courses/MAP/faqs/',
						label: t('courses:common.faqs'),
					},
					{
						path: countryPrefix + '/courses/MAP/instructors/',
						label: t('courses:common.instructor_team'),
					},
				]}
			/>
		</>
	)
}

export default CourseMAPNav
